<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Check Items - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-stepper 
            alt-labels
            v-model="steps"
            style="box-shadow: none;"
        >
            <v-layout align-center justify-center>
                <v-row style="display: contents !important;">
                    <v-col 
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                    >
                        <v-stepper-header
                            style="box-shadow: none"
                        >
                            <v-stepper-step
                                :complete="steps > 1"
                                step="1"
                            >
                                Check Items
                            </v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step
                                step="2"
                                style="min-width: 210px !important;"
                            >
                                Choose Drafting Team
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-col>
                </v-row>
            </v-layout>

            <v-stepper-items>

                <!-- STEP 1: CHECK ITEMS  -->
                <v-stepper-content step="1">

                    <v-expansion-panels popout>

                        <!-- SERVICE INFORMATIONS -->
                        <v-col
                            cols="12"
                        >                    
                            <JobInformation 
                                :id="serviceProjectResponse.idService"
                                :project="projectSelected"
                                :refreshJob="refreshJob"
                                :showProjects="false"
                                :showJobSummary="true"
                                :showButtonsCreateEditProject="false"
                                :showButtonsCreateEditVersion="false"
                                :showClassification="true"
                                :showDisabledClassification="false"
                                v-on:changeProject="updateProject"
                            />
                        </v-col>

                        <!-- SCOPE OF WORK -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-sitemap
                                            </v-icon>
                                            {{ titles.scopeOfWork }}
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>

                                    <v-row>

                                        <v-col
                                            cols="12"
                                        >

                                            <v-simple-table
                                                fixed-header
                                                style="cursor: pointer;"
                                            >
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                Category
                                                            </th>
                                                            <th class="text-left">
                                                                Sub Category
                                                            </th>
                                                            <th class="text-left">
                                                                Product
                                                            </th>
                                                            <th>
                                                                Notes
                                                            </th>
                                                            <th class="text-right">
                                                                Qty
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(item, index) in listProductSelecteds" :key="index"
                                                        >
                                                            <td>{{ item.categoryDescription }}</td>
                                                            <td>{{ item.subCategoryDescription }}</td>
                                                            <td>{{ item.productDescription }}</td>
                                                            <td>{{ item.note }}</td>
                                                            <td class="text-right">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                            <th class="text-left"></th>
                                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                                Grand Total
                                                            </th>
                                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                                {{ grandTotal }}
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.scopeOfWorkType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.scopeOfWorkType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.scopeOfWorkType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.scopeOfWorkType == 0"
                                right
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.scopeOfWorkType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.scopeOfWorkNotes != null && serviceDraftingRequest.scopeOfWorkNotes != undefined && serviceDraftingRequest.scopeOfWorkNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.scopeOfWork, serviceDraftingRequest.scopeOfWorkNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- DETAILS -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-information-variant
                                            </v-icon>
                                            Details
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-if="surveyFile && surveyFile.id != 0"
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, surveyFile.id, surveyFile.fileType, surveyFile.fileName)"
                                                    >
                                                        <v-card-title class="cardTitleDownload">
                                                            <label>Survey (PDF)</label>
                                                        </v-card-title>

                                                        <v-card-subtitle>
                                                            <span>{{ surveyFile.createdOn }} by {{ surveyFile.userName }}</span>
                                                        </v-card-subtitle>

                                                        <v-card-text>
                                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                            <span class="imageFileName">{{ surveyFile.fileName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                            
                                        </v-col>

                                        <v-col
                                            v-if="architecturalSitePlanFile && architecturalSitePlanFile.id != 0"
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, architecturalSitePlanFile.id, architecturalSitePlanFile.fileType, architecturalSitePlanFile.fileName)"
                                                    >
                                                        <v-card-title class="cardTitleDownload">
                                                            <label>Architectural Site Plan  (PDF, CAD, DWG)</label>
                                                        </v-card-title>

                                                        <v-card-subtitle>
                                                            <span>{{ architecturalSitePlanFile.createdOn }} by {{ architecturalSitePlanFile.userName }}</span>
                                                        </v-card-subtitle>

                                                        <v-card-text>

                                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                            <span class="imageFileName">{{ architecturalSitePlanFile.fileName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                            
                                        </v-col>

                                        <v-col
                                            v-if="poolStudioDXFFile && poolStudioDXFFile.id != 0"
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, poolStudioDXFFile.id, poolStudioDXFFile.fileType, poolStudioDXFFile.fileName)"
                                                    >
                                                        <v-card-title class="cardTitleDownload">
                                                            <label>Pool Studio File (DXF)</label>
                                                        </v-card-title>

                                                        <v-card-subtitle>
                                                            <span>{{ poolStudioDXFFile.createdOn }} by {{ poolStudioDXFFile.userName }}</span>
                                                        </v-card-subtitle>

                                                        <v-card-text>

                                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                            <span class="imageFileName">{{ poolStudioDXFFile.fileName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                            
                                        </v-col>

                                        <v-col
                                            v-if="poolStudioPDFFile && poolStudioPDFFile.id != 0"
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, poolStudioPDFFile.id, poolStudioPDFFile.fileType, poolStudioPDFFile.fileName)"
                                                    >
                                                        <v-card-title class="cardTitleDownload">
                                                            <label>Pool Studio File (PDF)</label>
                                                        </v-card-title>

                                                        <v-card-subtitle>
                                                            <span>{{ poolStudioPDFFile.createdOn }} by {{ poolStudioPDFFile.userName }}</span>
                                                        </v-card-subtitle>

                                                        <v-card-text>

                                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                            <span class="imageFileName">{{ poolStudioPDFFile.fileName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                            
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.detailsType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.detailsType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.detailsType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.detailsType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.detailsType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.detailsNotes != null && serviceDraftingRequest.detailsNotes != undefined && serviceDraftingRequest.detailsNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.details, serviceDraftingRequest.detailsNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- EQUIPMENT LOCATION -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-arrow-split-vertical
                                            </v-icon>
                                            Equipment Location
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in dimensionedEquipmentLocationPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.dimensionedEquipmentLocationNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.dimensionedEquipmentLocationType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.dimensionedEquipmentLocationType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.dimensionedEquipmentLocationType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.dimensionedEquipmentLocationType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.dimensionedEquipmentLocationType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.dimensionedEquipmentLocationNotes != null && serviceDraftingRequest.dimensionedEquipmentLocationNotes != undefined && serviceDraftingRequest.dimensionedEquipmentLocationNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.dimensionedEquipmentLocation, serviceDraftingRequest.dimensionedEquipmentLocationNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- POOL BARRIER -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left style="margin-top: -6px;">
                                                mdi-fence
                                            </v-icon>
                                            Pool Barrier
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>Fence Type</label>
                                            <v-combobox
                                                v-model="fenceTypeSelected"
                                                :items="listFenceType"
                                                item-text="description"
                                                item-value="id"
                                                chips
                                                multiple
                                                clearable
                                                outlined
                                                disabled
                                                dense
                                            ></v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.fenceEnclosureInformationType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.fenceEnclosureInformationType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.fenceEnclosureInformationType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.fenceEnclosureInformationType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.fenceEnclosureInformationType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.fenceEnclosureInformationNotes != null && serviceDraftingRequest.fenceEnclosureInformationNotes != undefined && serviceDraftingRequest.fenceEnclosureInformationNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.fenceEnclosureInformation, serviceDraftingRequest.fenceEnclosureInformationNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- FENCE LOCATION -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-fence-electric
                                            </v-icon>
                                            Fence Locations
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in fenceLocationPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.fenceLocationNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.fenceLocationType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.fenceLocationType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.fenceLocationType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.fenceLocationType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.fenceLocationType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.fenceLocationNotes != null && serviceDraftingRequest.fenceLocationNotes != undefined && serviceDraftingRequest.fenceLocationNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.fenceLocation, serviceDraftingRequest.fenceLocationNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- DEPTH AND DEEPEST POINT DESIRED LOCATION -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-graph-outline
                                            </v-icon>
                                            Depth and Deepest Point Desired Location
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in depthDeepestPointLocationPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.depthDeepestPointLocationNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.depthDeepestPointLocationType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.depthDeepestPointLocationType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.depthDeepestPointLocationType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.depthDeepestPointLocationType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.depthDeepestPointLocationType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.depthDeepestPointLocationNotes != null && serviceDraftingRequest.depthDeepestPointLocationNotes != undefined && serviceDraftingRequest.depthDeepestPointLocationNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.depthDeepestPointLocation, serviceDraftingRequest.depthDeepestPointLocationNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- LIGHT LOCATION -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-lightbulb-question-outline
                                            </v-icon>
                                            Light Locations
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in lightLocationsPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.lightLocationsNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.lightLocationsType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.lightLocationsType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.lightLocationsType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.lightLocationsType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.lightLocationsType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.lightLocationsNotes != null && serviceDraftingRequest.lightLocationsNotes != undefined && serviceDraftingRequest.lightLocationsNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.lightLocations, serviceDraftingRequest.lightLocationsNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- SOIL TEST -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-floor-plan
                                            </v-icon>
                                            Soil Test
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-if="soilTestFile && soilTestFile.id != 0"
                                            cols="12"
                                            lg="3"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, soilTestFile.id, soilTestFile.fileType, soilTestFile.fileName)"
                                                    >
                                                        <v-card-title class="cardTitleDownload">
                                                            <label>Soil Test (PDF)</label>
                                                        </v-card-title>

                                                        <v-card-subtitle>
                                                            <span>{{ soilTestFile.createdOn }} by {{ soilTestFile.userName }}</span>
                                                        </v-card-subtitle>

                                                        <v-card-text>
                                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                            <span class="imageFileName">{{ soilTestFile.fileName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                            
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.soilTestType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.soilTestType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.soilTestType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>

                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.soilTestType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.soilTestType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.soilTestNotes != null && serviceDraftingRequest.soilTestNotes != undefined && serviceDraftingRequest.soilTestNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.soilTest, serviceDraftingRequest.soilTestNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- PERGOLAS -->
                        <v-col
                            v-if="isProjectPergola"
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-home-edit-outline
                                            </v-icon>
                                            Pergolas
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in pergolasPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.pergolasNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            v-if="isProjectPergola"
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.pergolasType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.pergolasType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.pergolasType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.pergolasType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.pergolasType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.pergolasNotes != null && serviceDraftingRequest.pergolasNotes != undefined && serviceDraftingRequest.pergolasNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.pergolas, serviceDraftingRequest.pergolasNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <!-- WATER FEATURES -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-pool
                                            </v-icon>
                                            Water Features
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in waterFeaturesPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.waterFeaturesNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle
                                v-model="serviceDraftingRequest.waterFeaturesType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.waterFeaturesType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.waterFeaturesType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.waterFeaturesType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.waterFeaturesType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.waterFeaturesNotes != null && serviceDraftingRequest.waterFeaturesNotes != undefined && serviceDraftingRequest.waterFeaturesNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.waterFeatures, serviceDraftingRequest.waterFeaturesNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- BBQs -->
                        <v-col
                            v-if="isProjectBBQ"
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-grill
                                            </v-icon>
                                            BBQ´s
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in bbqPhotos"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="6"
                                            sm="12"
                                        >
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-card
                                                        class="cardDownload"
                                                        v-on="on"
                                                        @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                    >
                                                        <v-card-title class="cardTitlePhotosDownload">
                                                            <label>{{ itemPhoto.fileName }}</label>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="200"
                                                                max-width="250"
                                                                class="cardImagePhotosDownload"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                                <span>Click to Download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <label>Notes</label>
                                            <v-textarea
                                                v-model="serviceTechSpecRequest.bbqNotes"
                                                rows="4"
                                                row-height="30"
                                                auto-grow
                                                outlined
                                                disabled
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            v-if="isProjectBBQ"
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingRequest.bbqType"
                            >
                                <v-btn 
                                    @click="serviceDraftingRequest.bbqType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingRequest.bbqType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingRequest.bbqType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingRequest.bbqType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingRequest.bbqNotes != null && serviceDraftingRequest.bbqNotes != undefined && serviceDraftingRequest.bbqNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.bbq, serviceDraftingRequest.bbqNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- Property Line and Elements -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-chart-line-variant
                                            </v-icon>
                                            Property line and Elements
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.propertyLineAndElementsType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.propertyLineAndElementsType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.propertyLineAndElementsType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.propertyLineAndElementsType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.propertyLineAndElementsType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.propertyLineAndElementsNotes != null && serviceDraftingTest.propertyLineAndElementsNotes != undefined && serviceDraftingTest.propertyLineAndElementsNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.propertyLineAndElements, serviceDraftingTest.propertyLineAndElementsNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- Overall Dimension of Pool & Deck -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-triforce
                                            </v-icon>
                                            Overall Dimension of Pool & Deck
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.overallDimensionPoolDeckType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.overallDimensionPoolDeckType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.overallDimensionPoolDeckType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.overallDimensionPoolDeckType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.overallDimensionPoolDeckType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.overallDimensionPoolDeckNotes != null && serviceDraftingTest.overallDimensionPoolDeckNotes != undefined && serviceDraftingTest.overallDimensionPoolDeckNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.overallDimensionPoolDeck, serviceDraftingTest.overallDimensionPoolDeckNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- Setback Dimension -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-triforce
                                            </v-icon>
                                            Setback Dimension
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.setbackDimensionType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.setbackDimensionType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.setbackDimensionType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.setbackDimensionType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.setbackDimensionType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.setbackDimensionNotes != null && serviceDraftingTest.setbackDimensionNotes != undefined && serviceDraftingTest.setbackDimensionNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.setbackDimension, serviceDraftingTest.setbackDimensionNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- Shallow and Deep End -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-triforce
                                            </v-icon>
                                            Shallow and Deep End
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.shallowDeepEndType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.shallowDeepEndType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.shallowDeepEndType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.shallowDeepEndType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.shallowDeepEndType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.shallowDeepEndNotes != null && serviceDraftingTest.shallowDeepEndNotes != undefined && serviceDraftingTest.shallowDeepEndNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.shallowDeepEnd, serviceDraftingTest.shallowDeepEndNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- All Features -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-triforce
                                            </v-icon>
                                            All Features
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.allFeaturesType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.allFeaturesType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.allFeaturesType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.allFeaturesType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.allFeaturesType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.allFeaturesNotes != null && serviceDraftingTest.allFeaturesNotes != undefined && serviceDraftingTest.allFeaturesNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.allFeatures, serviceDraftingTest.allFeaturesNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- Theraphy Jet -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-triforce
                                            </v-icon>
                                            Theraphy Jet
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.theraphyJetType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.theraphyJetType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.theraphyJetType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.theraphyJetType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.theraphyJetType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.theraphyJetNotes != null && serviceDraftingTest.theraphyJetNotes != undefined && serviceDraftingTest.theraphyJetNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.theraphyJet, serviceDraftingTest.theraphyJetNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>
                        
                        <!-- House Shown with Single Line -->
                        <v-col
                            style="padding-top: 10px !important;"
                            cols="10"
                            lg="9"
                            md="10"
                            sm="10"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                        >
                                            <v-icon color="primary" left>
                                                mdi-triforce
                                            </v-icon>
                                            House Shown with Single Line
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                            </v-expansion-panel>
                        </v-col>
                        <v-col
                            style="display: flex; align-content: center; align-items: center;"
                            cols="2"
                            lg="3"
                            md="2"
                            sm="2"
                        >
                            <v-btn-toggle 
                                v-model="serviceDraftingTest.houseShownSingleLineType"
                            >
                                <v-btn 
                                    @click="serviceDraftingTest.houseShownSingleLineType = 0" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleDenied"
                                >
                                    <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                </v-btn>

                                <v-btn 
                                    @click="serviceDraftingTest.houseShownSingleLineType = 1" 
                                    :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                    class="btnToogleApproved"
                                >
                                    <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                </v-btn>
                            </v-btn-toggle>

                            <v-tooltip 
                                v-if="serviceDraftingTest.houseShownSingleLineType == 0"
                                left
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-if="serviceDraftingTest.houseShownSingleLineType == 0"
                                        class="btnActions"
                                        :style="serviceDraftingTest.houseShownSingleLineNotes != null && serviceDraftingTest.houseShownSingleLineNotes != undefined && serviceDraftingTest.houseShownSingleLineNotes != '' ? 'width: 35px; height:35px !important;' : 'color: gray !important; width: 35px; height:35px !important;'"
                                        icon 
                                        small 
                                        outlined
                                        v-on="on"
                                    >
                                        <v-icon small @click="showItem(titles.houseShownSingleLine, serviceDraftingTest.houseShownSingleLineNotes)">mdi-message-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>View the Reason</span>
                            </v-tooltip>
                        </v-col>

                        <v-dialog
                            v-model="showDialogItemNote"
                            transition="dialog-top-transition"
                            persistent
                            width="800"
                            :fullscreen="$vuetify.breakpoint.mobile"
                        >
                        
                            <v-card>
                                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                    {{ itemTitle }}
                                </v-card-title>

                                <v-card-text style="margin-top: 20px;">
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>Explain the denied reason</label>
                                            
                                            <v-textarea
                                                v-model="itemReasonDescription"
                                                :disabled="userLoggedGetters.profile.readOnlyDrafting == 1"
                                                single-line
                                                rows="3"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <div style="width: 100%; text-align: center;">
                                        <v-btn 
                                            v-if="userLoggedGetters.profile.readOnlyDrafting == 1"
                                            class="mx-2"
                                            style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                            @click="showDialogItemNote = false"
                                        >
                                            Dismiss
                                        </v-btn>
                                        <v-btn
                                            v-if="userLoggedGetters.profile.readOnlyDrafting != 1"
                                            class="mx-2 buttonCancelDialog"
                                            style=""
                                            outlined
                                            @click="showDialogItemNote = false"
                                        >
                                            Cancel
                                        </v-btn>

                                        <v-btn
                                            v-if="userLoggedGetters.profile.readOnlyDrafting != 1"
                                            class="mx-2"
                                            outlined
                                            @click="saveItem()"
                                        >
                                            Save
                                        </v-btn>
                                    </div>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-expansion-panels>

                </v-stepper-content>

                <!-- STEP 2: DRAFTER TEAM -->
                <v-stepper-content step="2">
                    
                    <v-row>
                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Drafting Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="drafterTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemDrafter, index) in listDrafters"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemDrafter.userName }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Permit Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="permitTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemPermit, index) in listPermit"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemPermit.userName }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Electric Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="electricTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemTechTeam, index) in listElectricTech"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemTechTeam.name }}</v-list-item-title>
                                                            
                                                            <v-text-field
                                                                v-if="itemTechTeam.id == -1 && active"
                                                                v-model="electricTeamOtherName" 
                                                                single-line
                                                                outlined
                                                                dense
                                                                @keyup.prevent=""
                                                                @click.stop=""
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Plumbing Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="plumbingTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemTechTeam, index) in listPlumbingTech"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemTechTeam.name }}</v-list-item-title>
                                                            
                                                            <v-text-field
                                                                v-if="itemTechTeam.id == -1 && active"
                                                                v-model="plumbingTeamOtherName" 
                                                                single-line
                                                                outlined
                                                                dense
                                                                @keyup.prevent=""
                                                                @click.stop=""
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>

                        <v-col 
                            cols="12"
                            xlg="2"
                            lg="3"
                            md="4"
                            sm="12"
                        >
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                    justify-center
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        Choose Gas Team
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-list
                                            rounded
                                        >
                                            
                                            <v-list-item-group
                                                v-model="gasTeam"
                                                multiple
                                            >
                                                <v-list-item
                                                    v-for="(itemTechTeam, index) in listGasTech"
                                                    :key="index"
                                                >
                                                    <template v-slot:default="{ active, }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                :input-value="active"
                                                                color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ itemTechTeam.name }}</v-list-item-title>
                                                            
                                                            <v-text-field
                                                                v-if="itemTechTeam.id == -1 && active"
                                                                v-model="gasTeamOtherName" 
                                                                single-line
                                                                outlined
                                                                dense
                                                                @keyup.prevent=""
                                                                @click.stop=""
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>      
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>

                                </v-card>
                                
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>
            
        <!-- Buttons: NEXT | BACK -->
        
        <div 
            v-if="userLoggedGetters.profile.readOnlyDrafting == 1"
            class="text-center"
        >
            
            <v-row>
                <v-col 
                    style="padding: 15px"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="back()"
                            >
                                Back
                            </v-btn>
                        </template>
                        <span>Back</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <div 
            v-else
            class="text-center"
        >
            <v-row
                style="padding-top: 50px"
            >
                <v-col 
                    style="text-align: right"
                    cols="6"
                    lg="5"
                    md="4"
                    sm="6"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                @click="cancel"
                                style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            >
                                Cancel
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                </v-col>
                <v-col 
                    style="text-align: center"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                :disabled="steps == 1"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="steps = 1"
                            >
                                Back
                            </v-btn>
                        </template>
                        <span>Back Previous Step</span>
                    </v-tooltip>
                </v-col>
                
                <v-col 
                    v-if="steps == 1"
                    style="text-align: left"
                    cols="12"
                    lg="5"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                @click="saveDrafting()"
                            >
                                Next
                            </v-btn>
                        </template>
                        <span>Go To Next Step</span>
                    </v-tooltip>
                </v-col>
                <v-col 
                    v-if="steps == 2"
                    style="text-align: left"
                    cols="12"
                    lg="5"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                @click="validateAndFinishDrafting()"
                            >
                                Finish
                            </v-btn>
                        </template>
                        <span>Finish</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import { formaterDecimalBR } from '@/utilities/Utils';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        CATEGORY_TEAM, 
        CONTRACTOR_CATEGORY, 
        URL_ATTACHMENT,
        PROJECT_STATUS
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({
            URL_ATTACHMENT,

            steps: 1,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,
            refreshJob: false,

            serviceTechSpecRequest: {
                id: 0,
                idService: 0,
                surveyTypeSelected: null,
                dimensionedEquipmentLocation: 0,
                dimensionedEquipmentLocationNotes: "",
                fenceType: "",
                fenceLocation: 0,
                fenceLocationNotes: "",
                depthDeepestPointLocation: 0,
                depthDeepestPointLocationNotes: "",
                lightLocations: 0,
                lightLocationsNotes: "",
                soilTest: 0,
                pergolas: 0,
                pergolasNotes: "",
                waterFeatures: 0,
                waterFeaturesNotes: "",
                bbq: 0,
                bbqNotes: "",                
            },

            titles: {
                serviceInformation: "Job information",
                scopeOfWork: "Scope of Work",
                details: "Attachments",
                dimensionedEquipmentLocation: "Equipment Location",
                fenceEnclosureInformation: "Pool Barrier",
                fenceLocation: "Fence Location",
                depthDeepestPointLocation: "Depth Deepest Point Location",
                lightLocations: "Light Locations",
                soilTest: "Soil Test",
                pergolas: "Pergolas",
                waterFeatures: "Water Features",
                bbq: "BBQ´s",
                propertyLineAndElements: "Property line and Elements",
                overallDimensionPoolDeck: 'Overall Dimension of Pool & Deck',
                setbackDimension: 'Setback Dimension',
                shallowDeepEnd: 'Shallow and Deep End',
                allFeatures: 'All features',
                theraphyJet: 'Theraphy Jet',
                houseShownSingleLine: 'House Shown with Single Line',
            },

            serviceDraftingRequest: {
                id: 0,
                idService: 0,
                serviceInformationType: null,
                serviceInformationNotes: "",
                scopeOfWorkType: null,
                scopeOfWorkNotes: "",
                detailsType: null,
                detailsNotes: "",
                dimensionedEquipmentLocationType: null,
                dimensionedEquipmentLocationNotes: "",
                fenceEnclosureInformationType: null,
                fenceEnclosureInformationNotes: "",
                fenceLocationType: null,
                fenceLocationNotes: "",
                depthDeepestPointLocationType: null,
                depthDeepestPointLocationNotes: "",
                lightLocationsType: null,
                lightLocationsNotes: "",
                soilTestType: null,
                soilTestNotes: "",
                pergolasType: null,
                pergolasNotes: "",
                waterFeaturesType: null,
                waterFeaturesNotes: "",
                bbqType: null,
                bbqNotes: "",
            },

            serviceDraftingTest: {
                propertyLineAndElementsType: null,
                propertyLineAndElementsNotes: '',
                overallDimensionPoolDeckType: null,
                overallDimensionPoolDeckNotes: '',
                setbackDimensionType: null,
                setbackDimensionNotes: '',
                shallowDeepEndType: null,
                shallowDeepEndNotes: '',
                allFeaturesType: null,
                allFeaturesNotes: '',
                theraphyJetType: null,
                theraphyJetNotes: '',
                houseShownSingleLineType: null,
                houseShownSingleLineNotes: ''
            },

            statusService: 0,

            itemTitle: "",
            itemReasonDescription: "",
            showDialogItemNote: false,

            listProductSelecteds: [],

            idServiceItem: 0,

            grandTotal: "$ 0.00",

            surveyFile: {
                id: 0,
                fileName: "",
                fileType: "",
                createdOn: "",
                userName: "",
            },

            poolStudioDXFFile: {
                id: 0,
                fileName: "",
                fileType: "",
                createdOn: "",
                userName: "",
            },

            poolStudioPDFFile: {
                id: 0,
                fileName: "",
                fileType: "",
                createdOn: "",
                userName: "",
            },

            architecturalSitePlanFile: {
                id: 0,
                fileName: "",
                fileType: "",
                createdOn: "",
                userName: "",
            },

            dimensionedEquipmentLocationPhotos: [],
            fenceLocationPhotos: [],
            depthDeepestPointLocationPhotos: [],
            lightLocationsPhotos: [],

            soilTestFile: {
                id: 0,
                fileName: "",
                fileType: "",
                createdOn: "",
                userName: "",
            },

            pergolasPhotos: [],
            waterFeaturesPhotos: [],
            bbqPhotos: [],

            fenceTypeSelected: [],

            listSurveyType: [
                { id: 1, description: 'Temp' },
                { id: 2, description: 'Final' },
                { id: 3, description: 'More Than 5 Year' }
            ],

            listFenceType: [
                { id: 1, description: 'Pool Cover' },
                { id: 2, description: 'Baby Fence' },
                { id: 3, description: 'Alarm' },
                { id: 4, description: 'Combination' },
                { id: 5, description: 'Screen Enclosure' },
                { id: 6, description: 'AI Cameras' },
                { id: 7, description: 'Perimeter Fence' },
                { id: 8, description: 'Floating Alarm' },
                { id: 9, description: 'Cooping Alarm' },
            ],

            listDrafters: [],
            drafterTeam: [],
            listPermit: [],
            permitTeam: [],
            listElectricTech: [],
            electricTeam: [],
            electricTeamOtherName: '',
            listPlumbingTech: [],
            plumbingTeam: [],
            plumbingTeamOtherName: '',
            listGasTech: [],
            gasTeam: [],
            gasTeamOtherName: '',

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
            
            validations: {
                required: required,
            },

            PROJECT_STATUS,

        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState', menuGetter: 'menuState' }),

            isProjectBBQ() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.bbq == 1;
            },

            isProjectPergola() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pergola == 1;
            },
        },

        methods: {
            
            async back() {
                history.go(-1);
            },

            async getTechSpec() {

                let responseTechSpec = await this.$store.dispatch("serviceModule/GetTechSpecById", this.id);
                this.serviceTechSpecRequest = responseTechSpec.result;
                this.serviceTechSpecRequest.surveyTypeSelected = {
                    id: this.serviceTechSpecRequest.surveyType,
                    description: this.serviceTechSpecRequest.surveyTypeDescription
                }
                
                let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: this.id });

                //DETAILS
                let itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];               
                if (itemSessionDetailSurveyType != null && itemSessionDetailSurveyType != undefined) {

                    this.surveyFile = {
                        id: itemSessionDetailSurveyType.id,
                        fileName: itemSessionDetailSurveyType.fileName,
                        fileType: itemSessionDetailSurveyType.fileType,
                        createdOn: itemSessionDetailSurveyType.dateRegisterFormatted,
                        userName: itemSessionDetailSurveyType.userNameRegister
                    }
                }

                let itemSessionDetailPoolStudioDXF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_DXF.value)[0];
                if (itemSessionDetailPoolStudioDXF != null && itemSessionDetailPoolStudioDXF != undefined) {

                    this.poolStudioDXFFile = {
                        id: itemSessionDetailPoolStudioDXF.id,
                        fileName: itemSessionDetailPoolStudioDXF.fileName,
                        fileType: itemSessionDetailPoolStudioDXF.fileType,
                        createdOn: itemSessionDetailPoolStudioDXF.dateRegisterFormatted,
                        userName: itemSessionDetailPoolStudioDXF.userNameRegister
                    }
                }

                let itemSessionDetailPoolStudioPDF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_PDF.value)[0];
                if (itemSessionDetailPoolStudioPDF != null && itemSessionDetailPoolStudioPDF != undefined) {

                    this.poolStudioPDFFile = {
                        id: itemSessionDetailPoolStudioPDF.id,
                        fileName: itemSessionDetailPoolStudioPDF.fileName,
                        fileType: itemSessionDetailPoolStudioPDF.fileType,
                        createdOn: itemSessionDetailPoolStudioPDF.dateRegisterFormatted,
                        userName: itemSessionDetailPoolStudioPDF.userNameRegister
                    }
                }

                let itemSessionDetailArchitecturalSitePlan = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ARCHITECTURAL_SITE_PLAN.value)[0];
                if (itemSessionDetailArchitecturalSitePlan != null && itemSessionDetailArchitecturalSitePlan != undefined) {

                    this.architecturalSitePlanFile = {
                        id: itemSessionDetailArchitecturalSitePlan.id,
                        fileName: itemSessionDetailArchitecturalSitePlan.fileName,
                        fileType: itemSessionDetailArchitecturalSitePlan.fileType,
                        createdOn: itemSessionDetailArchitecturalSitePlan.dateRegisterFormatted,
                        userName: itemSessionDetailArchitecturalSitePlan.userNameRegister
                    }
                }
                
                //EQUIPMENT LOCATION
                let listItemSessionDimensionedEquipmentLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.value);  
                listItemSessionDimensionedEquipmentLocation.forEach(itemPhoto => {

                    this.dimensionedEquipmentLocationPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })

                // POOL BARRIER
                let listIDFenceType = this.serviceTechSpecRequest.fenceType.split(';');
                await listIDFenceType.forEach(itemFence => {
                    if (itemFence) {

                        this.fenceTypeSelected.push({
                            id: parseInt(itemFence),
                            description: this.listFenceType.filter(fen => fen.id == parseInt(itemFence))[0].description
                        })
                    }
                })
                
                //FENCE LOCATION
                let listItemSessionFenceLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.FENCE_LOCATION.value);
                listItemSessionFenceLocation.forEach(itemPhoto => {

                    this.fenceLocationPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //DEPH DEEPEST POINT LOCATION
                let listItemSessionDepthDeepestPointLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.value);  
                listItemSessionDepthDeepestPointLocation.forEach(itemPhoto => {

                    this.depthDeepestPointLocationPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //LIGHT LOCATIONS
                let listItemSessionLightLocations = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.LIGHT_LOCATIONS.value);
                listItemSessionLightLocations.forEach(itemPhoto => {

                    this.lightLocationsPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })

                //SOIL TEST
                let itemSessionSoilTest = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SOIL_TEST.value)[0];
                if (itemSessionSoilTest != null && itemSessionSoilTest != undefined) {
                    
                    this.soilTestFile = {
                        id: itemSessionSoilTest.id,
                        fileName: itemSessionSoilTest.fileName,
                        fileType: itemSessionSoilTest.fileType,
                        createdOn: itemSessionSoilTest.dateRegisterFormatted,
                        userName: itemSessionSoilTest.userNameRegister
                    }
                }
                
                //PERGOLAS
                let listItemSessionPergolas = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS.value);
                listItemSessionPergolas.forEach(itemPhoto => {

                    this.pergolasPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //WATER FEATURES
                let listItemSessionWaterFeatures = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.WATER_FEATURES.value);  
                listItemSessionWaterFeatures.forEach(itemPhoto => {

                    this.waterFeaturesPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //BBQ
                let listItemSessionBBQ = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ.value);  
                listItemSessionBBQ.forEach(itemPhoto => {

                    this.bbqPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const responseDrafting = await this.$store.dispatch("serviceModule/GetDraftingById", this.id);

                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }

                    if (responseDrafting.result.id != 0) {
                        this.serviceDraftingRequest = responseDrafting.result;

                        this.refreshJob = !this.refreshJob;

                        const listTeam = await this.$store.dispatch("serviceModule/GetTeam", this.id);

                        this.drafterTeam = [];

                        if (listTeam.length > 0) {
                            let index = 0
                            listTeam.forEach ( () => {
                                this.drafterTeam.push(index);
                                index++;
                            })
                        }
                    }

                    await this.getTechSpec();
                }
            },

            async updateProject(projectSelected) {

                this.showLoading();

                this.projectSelected = projectSelected;

                this.hideLoading();
            },

            async getLists() {
                
                this.listTreeView = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: this.id, type: 'SERVICE', showOnlyCreatedItems: false, fromHistory: false });
                this.listTemplate = await this.$store.dispatch("templateModule/List");
                this.updateItems();
            },

            async updateItems() {
                
                this.listProductSelecteds = [];
                let grandTotal = 0;
                this.listTreeView.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                if (itemProduct.qty != null && itemProduct.qty != undefined && itemProduct.qty != "0.00" && itemProduct.qty != "" ) {

                                    let qty = parseFloat(itemProduct.qty == '' ? 0 : itemProduct.qty);
                                    
                                    let total = (qty * itemProduct.price).toFixed(2);

                                    this.listProductSelecteds.push({
                                        id: itemProduct.idServiceItem,
                                        idService: this.id,
                                        idCategory: itemCategory.id,
                                        categoryDescription: itemCategory.name,
                                        idSubCategory: itemSubCategoryOrProduct.id,
                                        subCategoryDescription: itemSubCategoryOrProduct.name,
                                        productDescription: itemProduct.name,
                                        idProduct: itemProduct.idTable,
                                        qty: itemProduct.qty,
                                        unitySymbol: itemProduct.unitySymbol,
                                        price: formaterDecimalBR(itemProduct.price),
                                        total: formaterDecimalBR(total),
                                        note: itemProduct.note,
                                    })
                                    grandTotal += parseFloat(total);
                                }
                                else {
                                    itemProduct.qty = '';
                                }
                            });
                        }
                        else {
                            
                            if (itemSubCategoryOrProduct.qty != null && itemSubCategoryOrProduct.qty != undefined && itemSubCategoryOrProduct.qty != "0.00" && itemSubCategoryOrProduct.qty != "" ) {

                                let qty = parseFloat(itemSubCategoryOrProduct.qty == '' ? 0 : itemSubCategoryOrProduct.qty);
                                    
                                let total = (qty * itemSubCategoryOrProduct.price).toFixed(2);

                                this.listProductSelecteds.push({
                                    id: itemSubCategoryOrProduct.idServiceItem,
                                    idService: this.id,
                                    idCategory: itemCategory.id,
                                    categoryDescription: itemCategory.name,
                                    idSubCategory: 0,
                                    subCategoryDescription: "",
                                    productDescription: itemSubCategoryOrProduct.name,
                                    idProduct: itemSubCategoryOrProduct.idTable,
                                    qty: itemSubCategoryOrProduct.qty,
                                    unitySymbol: itemSubCategoryOrProduct.unitySymbol,
                                    price: formaterDecimalBR(itemSubCategoryOrProduct.price),
                                    total: formaterDecimalBR(total),
                                    note: itemSubCategoryOrProduct.note,
                                })
                                grandTotal += parseFloat(total);
                            }
                            else {
                                itemSubCategoryOrProduct.qty = ''
                            }
                        }
                    });
                });
                this.grandTotal = `$ ${formaterDecimalBR(grandTotal)}`;
            },

            async cancel() {
                this.$router.push({ path: "/drafting/pipeline" });
            },

            async showItem(itemTitle, itemDescription) {
                this.itemTitle = itemTitle;
                this.itemReasonDescription = itemDescription;
                this.showDialogItemNote = true;
            },

            async saveItem() {

                this.showDialogItemNote = false;

                switch (this.itemTitle) {
                    case this.titles.serviceInformation: 
                        this.serviceDraftingRequest.serviceInformationNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.scopeOfWork: 
                        this.serviceDraftingRequest.scopeOfWorkNotes = this.itemReasonDescription; 
                    break;
                    
                    case this.titles.details: 
                        this.serviceDraftingRequest.detailsNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.dimensionedEquipmentLocation: 
                        this.serviceDraftingRequest.dimensionedEquipmentLocationNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.fenceEnclosureInformation: 
                        this.serviceDraftingRequest.fenceEnclosureInformationNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.fenceLocation: 
                        this.serviceDraftingRequest.fenceLocationNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.depthDeepestPointLocation: 
                        this.serviceDraftingRequest.depthDeepestPointLocationNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.lightLocations: 
                        this.serviceDraftingRequest.lightLocationsNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.soilTest: 
                        this.serviceDraftingRequest.soilTestNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.pergolas: 
                        this.serviceDraftingRequest.pergolasNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.waterFeatures: 
                        this.serviceDraftingRequest.waterFeaturesNotes = this.itemReasonDescription; 
                    break;

                    case this.titles.bbq: 
                        this.serviceDraftingRequest.bbqNotes = this.itemReasonDescription; 
                    break;
                }
                
            },

            async validateCheckItems() {

                let result = {
                    success: true,
                    anyItemDenied: false
                };

                if (this.serviceDraftingRequest.scopeOfWorkType == null || this.serviceDraftingRequest.scopeOfWorkType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.scopeOfWork}`);
                }
                else if (this.serviceDraftingRequest.scopeOfWorkType == 0 && 
                        (this.serviceDraftingRequest.scopeOfWorkNotes == null || 
                         this.serviceDraftingRequest.scopeOfWorkNotes == undefined || 
                         this.serviceDraftingRequest.scopeOfWorkNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.scopeOfWork} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.detailsType == null || this.serviceDraftingRequest.detailsType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.details}`);
                }
                else if (this.serviceDraftingRequest.detailsType == 0 && 
                        (this.serviceDraftingRequest.detailsNotes == null || 
                         this.serviceDraftingRequest.detailsNotes == undefined || 
                         this.serviceDraftingRequest.detailsNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.details} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.dimensionedEquipmentLocationType == null || this.serviceDraftingRequest.dimensionedEquipmentLocationType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.dimensionedEquipmentLocation}`);
                }
                else if (this.serviceDraftingRequest.dimensionedEquipmentLocationType == 0 && 
                        (this.serviceDraftingRequest.dimensionedEquipmentLocationNotes == null || 
                         this.serviceDraftingRequest.dimensionedEquipmentLocationNotes == undefined || 
                         this.serviceDraftingRequest.dimensionedEquipmentLocationNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.dimensionedEquipmentLocation} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.fenceEnclosureInformationType == null || this.serviceDraftingRequest.fenceEnclosureInformationType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.fenceEnclosureInformation}`);
                }
                else if (this.serviceDraftingRequest.fenceEnclosureInformationType == 0 && 
                        (this.serviceDraftingRequest.fenceEnclosureInformationNotes == null || 
                         this.serviceDraftingRequest.fenceEnclosureInformationNotes == undefined || 
                         this.serviceDraftingRequest.fenceEnclosureInformationNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.fenceEnclosureInformation} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.fenceLocationType == null || this.serviceDraftingRequest.fenceLocationType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.fenceLocation}`);
                }
                else if (this.serviceDraftingRequest.fenceLocationType == 0 && 
                        (this.serviceDraftingRequest.fenceLocationNotes == null || 
                         this.serviceDraftingRequest.fenceLocationNotes == undefined || 
                         this.serviceDraftingRequest.fenceLocationNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.fenceLocation} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.depthDeepestPointLocationType == null || this.serviceDraftingRequest.depthDeepestPointLocationType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.depthDeepestPointLocation}`);
                }
                else if (this.serviceDraftingRequest.depthDeepestPointLocationType == 0 && 
                        (this.serviceDraftingRequest.depthDeepestPointLocationNotes == null || 
                         this.serviceDraftingRequest.depthDeepestPointLocationNotes == undefined || 
                         this.serviceDraftingRequest.depthDeepestPointLocationNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.depthDeepestPointLocation} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.lightLocationsType == null || this.serviceDraftingRequest.lightLocationsType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.lightLocations}`);
                }
                else if (this.serviceDraftingRequest.lightLocationsType == 0 && 
                        (this.serviceDraftingRequest.lightLocationsNotes == null || 
                         this.serviceDraftingRequest.lightLocationsNotes == undefined || 
                         this.serviceDraftingRequest.lightLocationsNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.lightLocations} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.soilTestType == null || this.serviceDraftingRequest.soilTestType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.soilTest}`);
                }
                else if (this.serviceDraftingRequest.soilTestType == 0 && 
                        (this.serviceDraftingRequest.soilTestNotes == null || 
                         this.serviceDraftingRequest.soilTestNotes == undefined || 
                         this.serviceDraftingRequest.soilTestNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.soilTest} and must provide a reason.`);
                }

                else if (this.isProjectPergola == true && (this.serviceDraftingRequest.pergolasType == null || this.serviceDraftingRequest.pergolasType == undefined)) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.pergolas}`);
                }
                else if (this.isProjectPergola == true &&
                         this.serviceDraftingRequest.pergolasType == 0 && 
                        (this.serviceDraftingRequest.pergolasNotes == null || 
                         this.serviceDraftingRequest.pergolasNotes == undefined || 
                         this.serviceDraftingRequest.pergolasNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.pergolas} and must provide a reason.`);
                }

                else if (this.serviceDraftingRequest.waterFeaturesType == null || this.serviceDraftingRequest.waterFeaturesType == undefined) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.waterFeatures}`);
                }
                else if (this.serviceDraftingRequest.waterFeaturesType == 0 && 
                        (this.serviceDraftingRequest.waterFeaturesNotes == null || 
                         this.serviceDraftingRequest.waterFeaturesNotes == undefined || 
                         this.serviceDraftingRequest.waterFeaturesNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.waterFeatures} and must provide a reason.`);
                }

                else if (this.isProjectBBQ == true && (this.serviceDraftingRequest.bbqType == null || this.serviceDraftingRequest.bbqType == undefined)) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must select APPROVED or DENIED in ${this.titles.bbq}`);
                }
                else if (this.isProjectBBQ == true &&
                         this.serviceDraftingRequest.bbqType == 0 && 
                        (this.serviceDraftingRequest.bbqNotes == null || 
                         this.serviceDraftingRequest.bbqNotes == undefined || 
                         this.serviceDraftingRequest.bbqNotes.toString().trim() == ''))  {

                    result.success = false;
                    result.anyItemDenied = true;
                    this.showToast("error", "Warning!", `You denied ${this.titles.bbq} and must provide a reason.`);
                }

                // result.anyItemDenied = this.serviceDraftingRequest.serviceInformationType == 0;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.scopeOfWorkType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.detailsType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.dimensionedEquipmentLocationType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.fenceEnclosureInformationType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.fenceLocationType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.depthDeepestPointLocationType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.lightLocationsType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.soilTestType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.pergolasType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.waterFeaturesType == 0 : result.anyItemDenied;
                result.anyItemDenied = result.anyItemDenied == false ? this.serviceDraftingRequest.bbqType == 0 : result.anyItemDenied;

                return result;
            },

            async validateAndFinishDrafting() {

                let result = await this.validateCheckItems();
                this.statusService = this.PROJECT_STATUS.DRAFTING;

                if (result.success)
                {
                    let drafterTeamOK = true;

                    if (this.steps == 2) {
                        if (this.drafterTeam == null || this.drafterTeam == undefined || this.drafterTeam == "" || this.drafterTeam.length <= 0) {
                            this.showToast("error", "Warning!", `You must select at least one drafter!`);
                            drafterTeamOK = false;
                        }
                    }
                    
                    if (drafterTeamOK)
                    {
                        let msgConfirmation = "You have approved all items, confirm your decision?";

                        if (result.anyItemDenied) {
                            this.statusService = this.PROJECT_STATUS.SALES_CONTRACTED;
                            msgConfirmation = `You have denied one or more items and the service will go back to sales person to check, confirm your decision?`;
                        }

                        this.dialog = {
                            show: true,
                            headerText: 'Confirmation',
                            bodyText: msgConfirmation,
                            methodConfirm: this.finishDrafting,
                            params: 0
                        };
                    }
                }
            },

            async finishDrafting() {

                let result = {
                    success: true
                }

                if (this.steps == 2) {
                    
                    const listAllTeamsSelected = [
                        {
                            categoryTeam: CATEGORY_TEAM.DRAFTER.value,
                            listTeamSelected: [...this.drafterTeam],
                            nameOther: null,
                            list: [...this.listDrafters]
                        },

                        {
                            categoryTeam: CATEGORY_TEAM.PERMITTECH.value,
                            listTeamSelected: [...this.permitTeam],
                            nameOther: null,
                            list: [...this.listPermit]
                        },

                        {
                            categoryTeam: CATEGORY_TEAM.ELECTRICTECH.value,
                            listTeamSelected: [...this.electricTeam],
                            nameOther: this.electricTeamOtherName,
                            list: [...this.listElectricTech] 
                        },

                        {
                            categoryTeam: CATEGORY_TEAM.PLUMBINGTECH.value,
                            listTeamSelected: [...this.plumbingTeam],
                            nameOther: this.plumbingTeamOtherName,
                            list: [...this.listPlumbingTech]
                        },

                        {
                            categoryTeam: CATEGORY_TEAM.GASTECH.value,
                            listTeamSelected: [...this.gasTeam],
                            nameOther: this.gasTeamOtherName,
                            list: [...this.listGasTech] 
                        },
                    ]

                    let listTeamRequest = [];

                    await listAllTeamsSelected.forEach(async itemTeamSelected => {

                        await itemTeamSelected.listTeamSelected.forEach(async itemTeam => {

                            const categoryTeam = itemTeamSelected.categoryTeam;
                            const idUserOrContractor = itemTeamSelected.list[itemTeam].id;
                            let nameOther = null;
                            if (idUserOrContractor == -1) {
                                nameOther = itemTeamSelected.nameOther
                            }

                            listTeamRequest.push({
                                id: 0,
                                idServiceProject: this.id,
                                categoryTeam,
                                idUserOrContractor,
                                nameOther
                            });
                        });
                    })

                    result = await this.$store.dispatch("serviceModule/AssignTeam", listTeamRequest);
                }

                if (result.success) {

                    const updateStatusRequest = {
                        id: this.id,
                        status: this.statusService,  //Drafting
                        statusPermitting: this.PROJECT_STATUS.PERMITTING_PLAN_APPROVAL_PENDING //Drafting
                    };

                    const result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                    if (result.success) {
                        this.showToast("success", "Success!", "Saved with successfully");
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Error!", result.message);
                    }
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

            },

            async saveDrafting() {
                
                this.showLoading();

                this.serviceDraftingRequest.idServiceProject = this.id;
                this.serviceDraftingRequest.serviceInformationType = 1;

                const resultValidate = await this.validateCheckItems();

                this.$store.dispatch("serviceModule/CreateUpdateDrafting", this.serviceDraftingRequest);

                if (resultValidate.success && resultValidate.anyItemDenied) {
                    this.statusService = 7;
                    const msgConfirmation = `You have denied one or more items and the service will go back to sales person to check, confirm your decision?`;

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: msgConfirmation,
                        methodConfirm: this.finishDrafting,
                        params: 0
                    };
                }
                else if (resultValidate.success) {

                    this.showToast("success", "Success!", "Drafting saved with successfully!");
                    await this.listAvailableTechs();
                    this.steps = 2;

                    this.getRegister();
                }
                else {
                    this.showToast("error", "Warning!", "Error to save drafting!");
                }

                this.hideLoading();
            },

            async listAvailableTechs() {
                this.listDrafters = await this.$store.dispatch("serviceModule/ListAvaliableDrafters");
                this.listPermit = await this.$store.dispatch("serviceModule/ListAvaliablePermitters");
                
                const listContractors = await this.$store.dispatch("contractorModule/List");

                const otherOption = {
                    id: -1,
                    name: "Other"
                }

                this.listElectricTech = listContractors.filter(item => item.idContractorCategory == CONTRACTOR_CATEGORY.Electric.value);
                this.listPlumbingTech = listContractors.filter(item => item.idContractorCategory == CONTRACTOR_CATEGORY.Plumbing.value);
                this.listGasTech = listContractors.filter(item => item.idContractorCategory == CONTRACTOR_CATEGORY.Gas.value);

                this.listElectricTech.push(otherOption);
                this.listPlumbingTech.push(otherOption);
                this.listGasTech.push(otherOption);
            },

        },

        async created() {

            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

</style>